<template>
  <app-module-view>
    <template slot="body">
      <app-tasrNews-filter></app-tasrNews-filter>
      <div class="top-buttons">
        <div class="d-inline-block">
          <app-checkbox
            id="don_npa_autorefresh"
            v-model="autoRefresh"
            :label="$t('auto_refresh')">
          </app-checkbox>
        </div>
      </div>
      <div v-if="autoRefresh">
        <app-autoloader :callback="getTasrNews" :height="2" :interval="120"></app-autoloader>
      </div>
      <div class="card">
        <div class="card-body">
          <app-preloader v-if="callingAPI"></app-preloader>
          <app-data-table
            :data="tasrNews"
            :totalCount="totalCount"
            :page="page"
            :config="dataTableConfig"
            @page-change="setPageAndGetRecords"
          >
          </app-data-table>
        </div>
      </div>
    </template>
  </app-module-view>
</template>

<script>
import ModuleView from '../ModuleView'
import { mapState } from 'vuex'
import Preloader from '../preloader/Preloader'
import TasrNewsFilter from './TasrNewsFilter'
import DataTable from '../shared/DataTable'
import ValueObjectServiceTypeService from '../../services/tasr/ValueObjectServiceTypeService'
import ValueObjectStatusService from '../../services/tasr/ValueObjectStatusService'
import TasrNewsCategoryService from '../../services/tasr/TasrNewsCategoryService'
import Autoloader from '../Autoloader'
import Checkbox from '../form/Checkbox'
import CoreApi from '../../api/core'

export default {
  name: 'TasrNewsList',
  data () {
    return {
      dataLoaded: false,
      autoRefresh: true,
      articles: [],
      dataTableConfig: {
        fields: {
          id: this.$t('tasrNews.list.id'),
          headline: this.$t('tasrNews.list.headline'),
          service: this.$t('tasrNews.list.service'),
          category: this.$t('tasrNews.list.category'),
          status: this.$t('tasrNews.list.status'),
          articles: this.$t('tasrNews.list.sites'),
          contentCreated: this.$t('tasrNews.list.contentCreated')
        },
        actions: {
          custom: [
            {
              route_name: 'tasrNews_detail',
              route_param: 'id',
              route_source_param: 'id',
              icon: 'fa-info',
              title: this.$t('tasrNews.list.show'),
              text: this.$t('tasrNews.list.show')
            }
          ]
        },
        render: {
          service: function (data) {
            return (data) ? ValueObjectServiceTypeService.getPrettyName(data) : ''
          },
          status: function (data) {
            return ValueObjectStatusService.getListingIcon(data)
          },
          category: function (data) {
            const category = (data) ? TasrNewsCategoryService.getCategoryById(data) : ''
            return (typeof category !== 'undefined' && category !== '') ? category.title : ''
          },
          articles: function (data) {
            if (data) {
              const sites = []
              data.forEach(article => {
                const articleObj = this.getArticleByDocumentId(article.articleDocumentId)
                if (articleObj) {
                  sites.push(articleObj.expanded.site.shortTitle)
                }
              })
              return sites.join(', ')
            }
          }.bind(this)
        }
      }
    }
  },
  computed: {
    ...mapState([
      'callingAPI'
    ]),
    tasrNews () {
      return this.$store.getters['tasrNews/list']
    },
    totalCount () {
      return this.$store.getters['tasrNews/totalCount']
    },
    page () {
      return this.$store.getters['tasrNews/page']
    }
  },
  components: {
    appModuleView: ModuleView,
    appPreloader: Preloader,
    appTasrNewsFilter: TasrNewsFilter,
    appDataTable: DataTable,
    appAutoloader: Autoloader,
    appCheckbox: Checkbox
  },
  methods: {
    getTasrNews () {
      this.$store.dispatch('tasrNews/fetch')
        .then(() => {
          this.dataLoaded = true
        })
    },
    getArticleByDocumentId (documentId) {
      let articleObj = null
      this.articles.forEach(article => {
        if (article.documentId === documentId) {
          articleObj = article
        }
      })
      return articleObj
    },
    fetchArticles () {
      const articleDocumentIds = []

      this.tasrNews.forEach(tasrNews => {
        tasrNews.articles.forEach(article => {
          articleDocumentIds.push(article.articleDocumentId)
        })
      })

      if (articleDocumentIds.length === 0) {
        return
      }

      const url = '/article?view=expanded&filter_in[documentId]=' + articleDocumentIds.toString()
      CoreApi().get(url)
        .then(response => {
          if (response.data.totalCount > 0) {
            this.articles = response.data.data
          }
        })
        .catch(error => console.log(error))
    },
    setPageAndGetRecords (page) {
      this.$store.commit('tasrNews/setPage', page)
      this.getTasrNews()
    }
  },
  watch: {
    tasrNews () {
      this.fetchArticles()
    }
  },
  mounted () {
    this.getTasrNews()
  },
  beforeMount () {
    this.$store.dispatch('tasrNews/fetchValueObjectServiceType')
    this.$store.dispatch('tasrNews/fetchValueObjectStatus')
    this.$store.dispatch('tasrNewsCategory/fetchAll')
  }
}
</script>

<style lang="scss">

.table-responsive {
  td:last-child {
    width: 140px;
  }

  td:last-child a {
    margin-right: 2px;
  }
}

.card-no-border .card {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.top-buttons {
  position: absolute;
  top: -56px;
  right: 8px;
  z-index: 5;
}

</style>
